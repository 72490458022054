.item {
  border: 1px solid #555555;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;

  p {
    margin: 0;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .bold {
    font-weight: bold;
  }

  .win, .cashout {
    color: #008800;
  }

  .loss {
    color: #FF0000;
  }

  .pending, .void {
    color: #FF8800;
  }

  .bonus {
    color: #008800;
    font-weight: bold;

    > span {
      color: #000000;
    }
  }
}