.item {
  border: 1px solid #555555;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    margin: 0;
  }

  .bold {
    font-weight: bold;
  }

  .wager {
    text-align: center;
    display: flex;
    align-items: center;
    margin-top: 13px;

    > span:first-child {
      border: 1px solid black;
      padding: 2px 6px 3px 6px;
      border-radius: 12px;
      color: var(--sbUltraLight);
      margin-inline-start: auto;
    }

    > span:last-child {
      margin-inline-start: 8px;
      margin-inline-end: auto;

      .wagered {
        margin-inline-start: 4px;
        color: var(--sbLightest);
      }
    }
  }

  .progress {
    background-color: gray;
    border-radius: 8px;
    height: 8px;
    margin-top: 9px;
    position: relative;

    > div {
      border-radius: 8px;
      height: 8px;
      position: absolute;
    }

    .placed {
      background-color: red;
    }

    .settled {
      background-color: green;
    }
  }
}