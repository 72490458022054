.container {
  height: 100%;

  .selector {
    height: 40px;
    display: flex;
    justify-content: space-between;

    select {
      height: 30px;
      width: 200px;
      padding-inline-start: 6px;
    }

    button {
      height: 30px;
      cursor: pointer;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: calc(100% - 60px - 40px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}