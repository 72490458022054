.item {
  border: 1px solid #555555;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    margin: 0;
  }

  .bold {
    font-weight: bold;
  }
}