.pagination {
  margin: 11px -8px 0 -8px;
  border-top: 1px solid #555555;
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttons {
    display: flex;
    gap: 8px;
    align-items: center;

    button {
      cursor: pointer;
    }

    > span {
      font-size: 18px;
      line-height: 18px;
    }
  }
}