@import "../styles/css/normalize.css";

html, body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;

  > div {
    height: 100%;
    max-width: 800px;
    position: relative;
    margin: 0 auto;
  }
}