.container {
  height: calc(100% - 40px);

  .items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}