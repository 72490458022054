.container {
  padding: 8px;

  form {
    p {
      margin: 4px 0;
    }

    input {
      width: 200px;
      display: block;
      margin: 8px 0;
    }

    input[type=submit] {
      width: 100px;
    }
  }
}