.container {
  height: 100%;

  .buttons {
    height: 40px;
    display: flex;

    button {
      height: 30px;
      margin-inline-end: 8px;
      cursor: pointer;

      &:last-child {
        display: block;
        margin-inline-start: auto;
        margin-inline-end: 0;
      }
    }
  }
}