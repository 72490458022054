.container {
  border: 1px solid #555555;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: calc(100% - 32px);
  overflow-x: hidden;
  overflow-y: auto;

  p {
    margin: 0;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .type {
    text-align: center;
    font-size: 18px;
  }

  .bold {
    font-weight: bold;
  }

  .win, .cashout {
    color: #008800;
  }

  .loss {
    color: #FF0000;
  }

  .pending, .void {
    color: #FF8800;
  }

  .bonus {
    color: #008800;
    font-weight: bold;

    > span {
      color: #000000;
    }
  }

  .selections {
    > p {
      text-align: center;
      font-size: 18px;
    }

    .selection {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      gap: 4px;

      &:not(:last-child) {
        border-bottom: 1px solid #555555;
      }
    }
  }
}